import service from './network';

export function queryRestrictTypeListApi(data){
    return service.request({
        url: '/management/restrict/type/list',
        method: 'get',
        params: data
    })
}

export function queryRestrictChildListApi(data){
    return service.request({
        url: '/management/restrict/type/child',
        method: 'get',
        params: data
    })
}

export function queryRestrictListApi(data){
    return service.request({
        url: '/management/restrict/query',
        method: 'post',
        data
    })
}

export function updateRestrictApi(data){
    return service.request({
        url: '/management/restrict/update',
        method: 'put',
        data
    })
}

export function deleteRestrictApi(data){
    return service.request({
        url: '/management/restrict/delete',
        method: 'delete',
        data
    })
}

export function queryExceptionDataApi(data){
    return service.request({
        url: '/restrict/exception/data/query',
        method: 'post',
        data
    })
}

export function updateExceptionDataApi(data){
    return service.request({
        url: '/restrict/exception/data/update',
        method: 'put',
        data
    })
}

export function passExceptionDataApi(data){
    return service.request({
        url: '/restrict/exception/data/verify/pass',
        method: 'put',
        data
    })
}

export function refuseExceptionDataApi(data){
    return service.request({
        url: '/restrict/exception/data/verify/refuse',
        method: 'put',
        data
    })
}

export function deleteExceptionDataApi(data){
    return service.request({
        url: '/restrict/exception/data/delete',
        method: 'delete',
        data
    })
}

export function queryExceptionStatusListApi(data){
    return service.request({
        url: '/restrict/exception/data/status/list',
        method: 'get',
        params:data
    })
}

export function queryExceptionRegionTypeApi(data){
    return service.request({
        url: '/management/restrict/region/type',
        method: 'get',
        params:data
    })
}