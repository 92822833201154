<template>
    <a-modal v-model="visible" title="编辑" @ok="handleOk" width="720px" class="exception-detail">
        <vue-json-editor 
        style="height: 450px;"
        v-model="data"
        showBtns
        lang="zh"
        @json-save="onJsonSave" 
        :mode="'code'">
        </vue-json-editor>
    </a-modal>
</template>

<script>
import { updateExceptionDataApi} from '@/api/restrict'
import vueJsonEditor from 'vue-json-editor'
export default {
    components: {
        vueJsonEditor
    },
    data() {
        return {
            data: {},
            visible: false,
            exceptionCode: ''
        }
    },
    methods: {
        handleOk() {
            this.visible = false
            this.$emit('query')
        },
        showEdit(data) {
            this.exceptionCode = data.exceptionCode
            this.data = JSON.parse(JSON.parse(JSON.stringify(data.data, null, '  ')), null, '\n')
            this.visible = true
        },
        onJsonSave(e) {
            this.data = e
            updateExceptionDataApi({exceptionCode: this.exceptionCode, data: JSON.stringify(this.data)}).then(res => {
                this.$message.success('修改成功')
                this.$emit('query')
            }).catch(() => {
                this.$message.error('修改失败')
            })
        }
    }
}
</script>

<style lang="scss">
.exception-detail{
    .jsoneditor-vue{
        height: 80%;
    }
}
</style>