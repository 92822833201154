<template>
    <div>
        <a-form layout="inline">
            <a-form-item label="限制类别">
                <a-select
                        allowClear
                        placeholder="选择限制类别"
                        size="default"
                        style="width: 250px"
                        @change="handleChange"
                >
                    <a-select-option v-for="i in typeList" :key="i.typeConstant">
                        {{ i.typeName }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="状态">
                <a-select
                        allowClear
                        placeholder="选择状态"
                        size="default"
                        style="width: 250px"
                        @change="handleChangeStatus"
                >
                    <a-select-option v-for="i in statusList" :key="i.typeConstant">
                        {{ i.typeName }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="异常描述">
                <a-input v-model="params.exceptionName" allowClear placeholder="输入异常描述" @change="onSearch"/>
            </a-form-item>
            <a-form-item>
                <a-button style="margin-left: 24px;" type="primary" @click="onSearch"> 查询</a-button>
                <a-button style="margin-left: 24px;" type="default" @click="resetSearch"> 重置</a-button>
                <a-popconfirm
                        cancel-text="否"
                        ok-text="是"
                        title="确定删除?"
                        @confirm="deleteBatch"
                >
                    <a-button style="margin-left: 24px;" type="danger"> 批量删除</a-button>
                </a-popconfirm>
                <a-button style="margin-left: 24px;" @click="toLimitCondition"> 限制条件</a-button>
            </a-form-item>
        </a-form>
        <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                 bordered size="small"
                 style="margin-top: 24px;">
            <span slot="updatedTime" slot-scope="text, record"> {{ record.updatedTime | filterTime }}</span>
            <span slot="action" slot-scope="record">
                <a v-if="record.exceptionStatusStr == '等待处理'" style="margin-right: 10px;" @click="showEdit(record)">编辑</a>
                <a-popconfirm
                        v-if="record.exceptionStatusStr == '等待处理'"
                        cancel-text="否"
                        ok-text="是"
                        style="margin-right: 10px;"
                        title="确定入库?"
                        @confirm="confirmPass(record)"
                >
                <a>入库</a>
                </a-popconfirm>
                <a-popconfirm
                        v-if="record.exceptionStatusStr == '等待处理'"
                        cancel-text="否"
                        ok-text="是"
                        style="margin-right: 10px;"
                        title="确定作废?"
                        @confirm="confirmRefuse(record)"
                >
                <a>作废</a>
                </a-popconfirm>
                <a-popconfirm
                        cancel-text="否"
                        ok-text="是"
                        title="确定删除该项?"
                        @confirm="confirmDeleteSingle(record)"
                >
                    <a>删除</a>
                </a-popconfirm>
            </span>
        </a-table>
        <edit-exception-data ref="exception" @query="queryExceptionData"></edit-exception-data>
    </div>
</template>

<script>
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
};
import moment from 'moment'
import editExceptionData from '@/components/route/component/editExceptionData'
import {
    deleteExceptionDataApi,
    passExceptionDataApi,
    queryExceptionDataApi,
    queryExceptionStatusListApi,
    queryRestrictTypeListApi,
    refuseExceptionDataApi
} from '@/api/restrict'

export default {
    components: {editExceptionData},
    data() {
        return {
            rowSelection,
            selectedRowKeys: [],
            selectedRows: [],
            loading: false,
            params: {
                exceptionStatus: '',
                exceptionName: '',
                restrictType: '',
                page: 1,
                limit: 10
            },
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                    return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange: (current, pageSize) => this.changePage(current, pageSize),
            },
            columns: [
                {
                    title: '编号',
                    key: 'exceptionCode',
                    dataIndex: 'exceptionCode',
                    ellipsis: true,
                    width: 0
                },
                {
                    title: '异常描述',
                    key: 'exceptionName',
                    dataIndex: 'exceptionName',
                    ellipsis: true,
                    width: 0
                },
                {
                    title: '异常类别',
                    key: 'restrictTypeName',
                    dataIndex: 'restrictTypeName',
                    width: 200
                },
                {
                    title: '异常条件',
                    key: 'restrictCondition',
                    dataIndex: 'restrictCondition'
                },
                {
                    title: '状态',
                    key: 'exceptionStatusStr',
                    dataIndex: 'exceptionStatusStr',
                    width: 180
                },
                {
                    title: '上传信息',
                    key: 'data',
                    dataIndex: 'data',
                    ellipsis: true
                },
                {
                    title: '修改时间',
                    key: 'updatedTime',
                    dataIndex: 'updatedTime',
                    scopedSlots: {customRender: 'updatedTime'},
                    width: 200
                },
                {
                    title: '操作',
                    key: 'action',
                    scopedSlots: {customRender: 'action'},
                }
            ],
            data: [],
            typeList: [],
            statusList: []

        }
    },
    mounted() {
        this.queryExceptionData()
        this.queryRestrictTypeList()
        this.queryExceptionStatusList()
    },
    filters: {
        filterTime(value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        toLimitCondition() {
            this.$router.push({path: '/management/actualMgt/restrict'})
        },
        resetSearch() {
            this.params = {
                exceptionStatus: '',
                exceptionName: '',
                restrictType: '',
                page: 1,
                limit: 10
            }
            this.pagination.pageSize = 10
            this.pagination.current = 1
            this.queryExceptionData()
        },
        handleChangeStatus(value) {
            this.params.exceptionStatus = value
            this.onSearch()
        },
        queryExceptionStatusList() {
            queryExceptionStatusListApi().then(res => {
                this.statusList = res.result
            })
        },
        confirmDeleteSingle(record) {
            let ids = []
            ids.push(record.exceptionCode)
            this.deleteByIds(ids)
        },
        deleteBatch() {
            let ids = []
            if (this.selectedRowKeys.length > 0) {
                this.selectedRows.forEach(item => {
                    ids.push(item.exceptionCode)
                })
                this.deleteByIds(ids)
            } else {
                this.$$message.warning('尚未选择数据')
            }

        },
        deleteByIds(ids) {
            deleteExceptionDataApi(ids).then(res => {
                this.$message.success('删除成功')
                this.queryExceptionData()
                this.selectedRowKeys = []
                this.selectedRows = []
            }).catch(() => {
                this.$message.error('操作失败')
            })
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows;
            console.log(selectedRows)
        },
        confirmPass(record) {
            passExceptionDataApi({exceptionCode: record.exceptionCode}).then(res => {
                this.$message.success('入库成功')
                this.queryExceptionData()
            }).catch(() => {
                this.$message.error("入库失败")
            })
        },
        confirmRefuse(record) {
            refuseExceptionDataApi({exceptionCode: record.exceptionCode}).then(res => {
                this.$message.success('该信息已作废')
                this.queryExceptionData()
            }).catch(() => {
                this.$message.error("操作失败")
            })
        },
        showEdit(record) {
            this.$refs.exception.showEdit(record)
        },
        async queryRestrictTypeList() {
            let data = await queryRestrictTypeListApi()
            this.typeList = data.result
        },
        handleChange(value) {
            this.params.restrictType = value
            this.onSearch()
        },
        onSearch() {
            this.pagination.current = 1
            this.queryExceptionData()
        },
        queryExceptionData() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryExceptionDataApi({...this.params}).then(res => {
                this.data = res.result.content
                this.pagination.total = res.result.totalElements
                this.params.page = this.pagination.current
                this.loading = false

            })
        },
        changePage(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.queryExceptionData()
        }
    }
}
</script>